import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from './theme-provider';

export default function Select({
  className,
  menu,
  index,
  url,
  frame,
  setFrame,
}) {
  const [showMenu, setShowMenu] = useState(false);

  const theme = useContext(ThemeContext);

  return (
    <div
      css={css`
        flex-grow: 1;
        position: relative;
      `}
      onMouseLeave={() => setShowMenu(false)}
    >
      <div
        className={className}
        css={css`
          position: relative;
          width: auto;
          height: 50px;
          font-size: 30px;
          line-height: 50px;
          text-transform: uppercase;
          background-color: var(--white);
          padding: 0 20px;
          border-radius: 45px;
          padding-right: 45px;
          box-shadow: var(--shadow);
          margin: 0 5px;
          cursor: pointer;
          overflow: hidden;
          z-index: 1;
          overflow: hidden;

          @media (max-width: 512px) {
            font-size: 20px;
            height: 36px;
          }

          &:hover {
            .select-arrow {
              transform: translateY(3px);
            }
          }
        `}
        onClick={() => setShowMenu(true)}
      >
        <div
          css={css`
            width: 100%;
            height: 50px;

            @media (max-width: 512px) {
              height: 36px;
              line-height: 36px;
            }
          `}
        >{theme.getName(index)}</div>
        <div
          className="select-arrow"
          css={css`
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            right: 20px;
            height: 100%;
            transition: transform 0.4s var(--curve);
          `}
        >&darr;</div>
      </div>
      <div
        css={css`
          display: ${(showMenu) ? 'block' : 'none'};
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100% - 10px);
          font-size: 30px;
          line-height: 1.45;
          text-transform: uppercase;
          background-color: var(--white);
          padding: 4px 20px;
          border-radius: 20px;
          box-shadow: var(--shadow);
          margin: 0 5px;
          z-index: 2;

          @media (max-width: 512px) {
            font-size: 20px;
          }
        `}
      >
        {menu?.length > 0 && menu.map((item, i) => <div
          key={i}
          css={css`
            cursor: ${item.url ? 'pointer' : 'inherit'};

            &:hover {
              text-decoration: ${item.url ? 'underline' : 'inherit'};
              text-decoration-thickness: 2px;
            }

            hr {
              height: 2px;
              background-color: var(--black);
              border: 0;
            }
          `}
          onClick={() => {
            if (item.url) {
              if (item.external) {
                setShowMenu(false);
                const newWindow = window.open(item.url, '_blank');
                newWindow.focus();
              } else {
                setShowMenu(false);
                setFrame(item.url);
                theme.setName(index, item.name);
              }
            }
          }}
        >{item.name ? item.name : <hr />}</div>)}
      </div>
    </div>
  );
}
