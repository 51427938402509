import React from 'react';
import { css } from '@emotion/react';
import Select from './select';
import Button from './button';
import Random from './svg/random.svg';

export default function Frame({
  index,
  className,
  addUrl,
  menu,
  pages,
  url,
  canAdd,
  addFrame,
  closeFrame,
  setFrame,
  backFrame,
  canGoBack,
}) {

  return (
    <div
      className={className}
      css={css`
        position: relative;
      `}
    >
      <div
        css={css`
          display: flex;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          padding: var(--margin);
        `}
      >
        <Select
          menu={menu}
          url={url}
          index={index}
          setFrame={setFrame}
        />
        <Button
          onClick={() => {
            const page = pages[Math.floor(Math.random() * pages.length)];

            setFrame(page.url); 
          }}
        >
          <Random />
        </Button>
        <Button
          onClick={backFrame}
          disabled={!canGoBack}
        >
          &larr;
        </Button>
        <Button
          css={css`
            @media (max-width: 512px) {
              display: none;
            }
          `}
          onClick={() => {
            if (canAdd) {
              addFrame();
            } else {
              closeFrame();
            }
          }}
        >
          {canAdd && '+'}
          {!canAdd && <>&times;</>}
        </Button>
      </div>
      <iframe
        css={css`
          width: 100%;
          height: 100%;
          border: none;
        `}
        title={`frame-${index}`}
        src={url}
        data-index={index}
      />
    </div>
  );
}
