import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from './theme-provider';
import SanityImage from 'gatsby-plugin-sanity-image';

function getRandomPosition() {
  const x = Math.floor(Math.random() * (window.innerWidth) - 100);
  const y = Math.floor(Math.random() * (window.innerHeight) - 100);

  return { x, y };
}

export default function Floaters({ images, interval }) {
  const [imageElements, setImageElements] = useState([]);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    const timer = setInterval(() => {
      if (images.length > 0) {
        const randomIndex = Math.floor(Math.random() * images.length);
        const randomImage = images[randomIndex];
        const { x, y } = getRandomPosition();
        const uniqueKey = Date.now();

        setImageElements(prev => [
          ...prev,
          <img
            key={uniqueKey}
            data-unique-key={uniqueKey}
            css={css`
              position: fixed;
              left: ${x}px;
              top: ${y}px;
              width: auto;
              transition: all 3s ease;
              cursor: pointer;
              z-index: 100;
            `}
            src={randomImage.asset.url}
            onMouseEnter={() => {
              const newPosition = getRandomPosition();
              const newStyle = `
                left: ${newPosition.x}px;
                top: ${newPosition.y}px;
                animation: crawling 0.4s infinite;
                transform-origin: center center;
              `;
              const newAfterStyle = `
                left: ${newPosition.x}px;
                top: ${newPosition.y}px;
              `;

              const image = document.querySelector(`[data-unique-key="${uniqueKey}"]`);

              if (image.getAnimations().length === 0) {
                image.style.cssText = newStyle;

                setTimeout(() => {
                  if (image)
                    image.style.cssText = newAfterStyle;
                }, 3000);
              }
            }}
            onClick={(e) => removeImages()}
          />,
        ]);
      }
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [images, theme.lastMouseMovement]);

  const removeImage = (event) => {
    const uniqueKey = event.target.getAttribute('data-unique-key');

    setImageElements((prev) => prev.filter((img) => img.props['data-unique-key'].toString() !== uniqueKey));
  };

  const removeImages = () => {
    setImageElements([]);
  };

  return (
    <>
      {imageElements}
    </>
  );
}
