import React, { createContext, useEffect, useState } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [frames, setFrames] = useState([]);
  const [frameNames, setFrameNames] = useState([]);
  const [history, setHistory] = useState([]);
  const [activeTitle, setActiveTitle] = useState('');
  const [lastMouseMovement, setLastMouseMovement] = useState(Date.now());

  useEffect(() => {
    const handler = (e) => {
      if (e.data.source === 'west-space') {
        if (e.data.event === 'setFrame') setFrame(...e.data.payload);
        if (e.data.event === 'setName') {
          setName(...e.data.payload);
          setActiveTitle(`West Space – ${e.data.payload[1]}`);
          document.title = `West Space – ${e.data.payload[1]}`;
        }
        if (e.data.event === 'setTitle') setActiveTitle(`West Space – ${e.data.payload[1]}`);
        if (e.data.event === 'setLastMouseMovement') {
          setLastMouseMovement(Date.now());
        }
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [frames])

  function addHistory(i, url) {
    const newHistory = history;

    if (!newHistory[i]) newHistory[i] = [];

    newHistory[i].push({
      url: url,
    });

    setHistory([...newHistory]);
  }

  function addFrame(url) {
    setFrames([...frames, {
      url: url,
    }]);
  }

  function removeFrame(i) {
    const newFrames = [...frames];

    newFrames.splice(i, 1);

    setFrames([...newFrames]);
  }

  function setFrame(i, url, noHistory) {
    const newFrames = [...frames];

    newFrames[i] = {
      url: url,
    };

    setFrames([...newFrames]);

    const internal = /^\/(?!\/)/.test(url);

    if (internal) window.history.pushState({}, '', url)
    if (!noHistory) addHistory(i, url);
  }

  function backFrame(i) {
    const frameHistory = history[i];

    if (frameHistory?.length > 1) {
      const newFrameHistory = frameHistory;

      newFrameHistory.pop();

      setFrame(i, newFrameHistory[newFrameHistory.length - 1].url, true);

      const newHistory = history;

      newHistory[i] = newFrameHistory;

      setHistory([...newHistory]);
    }
  }

  function setName(i, name) {
    setFrameNames((data) => {
      data[i] = name;

      return [...data];
    });
  }

  function getName(i) {
    return frameNames[i];
  }

  return (
    <ThemeContext.Provider
      value={{
        frames,
        setFrames,
        history,
        setHistory,
        addHistory,
        addFrame,
        removeFrame,
        setFrame,
        backFrame,
        setName,
        getName,
        activeTitle,
        lastMouseMovement,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
