exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offsite-js": () => import("./../../../src/pages/offsite.js" /* webpackChunkName: "component---src-pages-offsite-js" */),
  "component---src-pages-whats-on-js": () => import("./../../../src/pages/whats-on.js" /* webpackChunkName: "component---src-pages-whats-on-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-program-js": () => import("./../../../src/templates/program.js" /* webpackChunkName: "component---src-templates-program-js" */),
  "component---src-templates-theme-js": () => import("./../../../src/templates/theme.js" /* webpackChunkName: "component---src-templates-theme-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

