import React, { useContext, useEffect } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from './theme-provider';
import Frame from './frame';

export default function FrameManager({
  className,
  menu,
  pages,
  srcs,
}) {
  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (window.innerWidth > 1024) {
      theme.setFrames(srcs);
    } else {
      theme.setFrames([srcs[0]]);
    }

    srcs.forEach((frame, i) => theme.addHistory(i, frame.url));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        if (theme.frames.length < 2) {
          theme.setFrames(theme.frames);
        }
      } else {
        if (theme.frames.length > 1) {
          theme.setFrames([theme.frames[0]]);
        }
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [theme.frames]);


  return (
    <div
      className={className}
      css={css`
        position: absolute;
        inset: 0 0 0 0;
        display: flex;
      `}
    >
      {theme.frames?.length > 0 && theme.frames.map((frame, i) => <Frame
        key={i}
        index={i}
        css={css`
          flex-grow: 1;
          width: 100%;
          height: 100%;
        `}
        menu={menu}
        pages={pages}
        url={frame.url}
        canAdd={theme.frames.length <= 1}
        addFrame={() => theme.addFrame('/')}
        closeFrame={() => theme.removeFrame(i)}
        setFrame={(name, url) => theme.setFrame(i, name, url)}
        backFrame={() => theme.backFrame(i)}
        canGoBack={theme.history[i].length > 1}
      />)}
    </div>
  );
}
