import React from 'react';
import { css } from '@emotion/react';

export default function Button({
  className,
  children,
  ...props
}) {
  return (
    <div
      className={className}
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        font-size: 30px;
        background-color: var(--white);
        border-radius: 100%;
        box-shadow: var(--shadow);
        margin: 0 5px;
        z-index: 1;
        cursor: pointer;
        user-select: none;
        transition: transform 0.4s var(--curve);

        @media (max-width: 512px) {
          font-size: 20px;
          height: 36px;
          width: 36px;
        }

        &:hover {
          transform: scale(1.05);
        }

        &[disabled] {
          cursor: auto;
          opacity: 0.4;

          &:hover {
            transform: none;
          }
        }
      `}
      {...props}
    >
      { children }
    </div>
  );
}
