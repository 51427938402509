import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Global, css } from '@emotion/react';
import * as normalize from '../styles/normalize.css';
import * as global from '../styles/global.css';
import { ThemeContext } from '../components/theme-provider';
import { bots } from '../components/bots';
import FrameManager from '../components/frame-manager';
import SEO from '../components/seo';
import Floaters from '../components/floaters';

export default function Layout({ children, location }) {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      programs: allSanityProgram(sort: {fields: startDate, order: ASC}) {
        nodes {
          title
          slug {
            current
          }
          endDate
        }
      }
      works: allSanityWork {
        nodes {
          title
          slug {
            current
          }
        }
      }
      menu: sanityMenu(_id: { regex: "/^(drafts\\.)?menu/" }) {
        items {
          ... on SanityPage {
            _type
            title
            slug {
              current
            }
          }
          ... on SanityLink {
            _type
            text
            url
          }
        }
      }
      siteSettings: sanitySiteSettings(_id: { regex: "/^(drafts\\.)?siteSettings/" }) {
        takeoverImages {
          asset {
            url
          }
        }
        takeoverInterval
      }
    }
  `);

  const [isBot, setIsBot] = useState(true);

  const theme = useContext(ThemeContext);

  const sibling = {
    url: (data.programs.nodes.length > 0 && data.programs.nodes.filter((item) => new Date(item.endDate) > new Date()).length === 0) ? '/archive' : '/whats-on',
  };
  const menu = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: `What's On`,
      url: '/whats-on',
    },
    ...data.menu.items.map((item) => {
      if (item) {
        if (item._type === 'page') {
          return {
            name: item.title,
            url: `/${item.slug?.current}`,
          };
        } else if (item._type === 'link') {
          return {
            name: item.text,
            url: item.url,
            external: true,
          };
        }
      } else {
        return {
          name: 'Unknown',
          url: '/',
        };
      }
    }),
    {
      name: '',
    },
    {
      name: 'Archive',
      url: '/archive',
    },
    {
      name: 'Offsite',
      url: '/offsite',
    },
  ];

  const allPages = [
    ...menu,
    ...data.programs.nodes.map((program) => ({ name: 'Archive', url: `/program/${program.slug?.current}`})),
    ...data.works.nodes.map((work) => ({ name: 'Offsite', url: `/offsite/work/${work.slug?.current}`})),
  ]

  const UA = (typeof window !== 'undefined') ? navigator.userAgent : '';
  const inFrame = (typeof window !== 'undefined') ? window !== window.top : true;

  useLayoutEffect(() => {
    setIsBot(bots.some((bot) => UA.includes(bot.pattern)));
  }, [UA]);

  useLayoutEffect(() => {
  }, [theme.activeTitle]);

  useEffect(() => {
    const handleMouseMove = () => {
      if (typeof window !== 'undefined') {
        if (window.top !== window && window.frameElement) {
          const i = parseInt(window.frameElement.getAttribute('data-index'), 10);

          window.top.postMessage({
              source: 'west-space',
              event: 'setLastMouseMovement',
              payload: [
                true
              ],
            },
            '*',
          );
        }
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <>
      <Global
        styles={css`
          ${normalize}
          ${global}
        `}
      />
      {(!isBot && !inFrame) && <Floaters
        images={data.siteSettings?.takeoverImages}
        interval={data.siteSettings?.takeoverInterval}
      />}
      <main
        css={css`
          > div {
            min-height: 100%;
            ${(isBot || inFrame) ? 'padding-top: 160px;' : ''}

            @media (max-width: 512px) {
              ${(isBot || inFrame) ? 'padding-top: 100px;' : ''}
            }
          }
        `}
      >
        {(isBot || inFrame) && children}
        {(!isBot && !inFrame) && <FrameManager
          menu={menu}
          pages={allPages}
          srcs={[{
            url: `${location.pathname}`,
          }, sibling]}
        />}
      </main>
    </>
  );
}
